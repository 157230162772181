export default {
  data() {
    return {
      isVisible: false,
      modalImagePath: '',
    }
  },
  mounted() {
    this.checkCookie()
    this.setModalImagePath()
  },
  methods: {
    checkCookie() {
      const cookieIdentifier = 'christmas_modal_shown'
      const cookies = document.cookie.split(';')
      const hasPfCookie = cookies.some(cookie => cookie.trim().startsWith(cookieIdentifier))

      if (!hasPfCookie) {
        this.isVisible = true
        const date = new Date()
        const days = 100
        date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * days)
        document.cookie = `${cookieIdentifier}=true;path=/;expires=${date.toGMTString()}`
      }
    },
    setModalImagePath() {
      const locale = this.$i18n.locale
      const modalImagePaths = {
        cs: '/assets/images/christmas/cvb-pf-2025-CZ.jpg',
        de: '/assets/images/christmas/cvb-pf-2025-DE.jpg',
      }

      this.modalImagePath = modalImagePaths[locale] || modalImagePaths.cs
    },
    closeModal() {
      this.isVisible = false
    },
  },
}
